import React from "react";
import theme from "theme";
import { Theme, Text, Em, List, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"terms-and-conditions"} />
		<Helmet>
			<title>
				Sandbox Mechanic Playpeople
			</title>
			<meta name={"description"} content={"Dive into an endless playground where you can build, design, and play with an interactive world full of mechanics and challenges. Create, explore, and invent in an open-ended adventure."} />
			<meta property={"og:title"} content={"Sandbox Mechanic Playpeople"} />
			<meta property={"og:description"} content={"Dive into an endless playground where you can build, design, and play with an interactive world full of mechanics and challenges. Create, explore, and invent in an open-ended adventure."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6731e834e5d2770018993c47/images/DALL%C2%B7E-2024-11-11-16.27.21-A-small_-detailed-favicon-representing-a-sandbox-mechanic-game.png?v=2024-11-11T14:28:25.883Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Components.Header />
		<Section>
			{"    "}
			<Text font="--headline2">
				{"        "}Terms and Conditions{"\n    "}
			</Text>
			{"    "}
			<Text>
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					{"            "}Last Updated: 20.10.2024{"\n        "}
				</Em>
				{"    "}
			</Text>
			{"    "}
			<Text font="--headline4">
				{"        "}1. Acceptance of Terms{"\n    "}
			</Text>
			{"    "}
			<Text>
				By downloading or using quoridynasty.com, you affirm that you are at least 18 years old or that you have the consent of a parent or guardian. If you are accessing the game on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline4">
				{"        "}2. License to Use the Game{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}We grant you a limited, non-exclusive, non-transferable, revocable license to access and use quoridynasty.com for personal, non-commercial purposes. You agree not to modify, distribute, or exploit any part of the game without our express written permission.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline4">
				{"        "}3. User Responsibilities{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}You are responsible for your use of the game and for any content you create, share, or interact with while using quoridynasty.com. You agree not to engage in any unlawful or prohibited activities, including but not limited to:{"\n    "}
			</Text>
			{"    "}
			<List list-style-type="circle" as="dot">
				<Text display="inline">
					Harassment or intimidation of other users
				</Text>
				<Text display="inline">
					Impersonation of any person or entity
				</Text>
				<Text display="inline">
					Interference with the operation of the game
				</Text>
			</List>
			{"    "}
			<Text font="--headline4">
				{"        "}4. Intellectual Property{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}All content, trademarks, and other intellectual property in quoridynasty.com are owned by quoridynasty.com or its licensors. You may not use, reproduce, or distribute any materials from the game without our prior written consent.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline4">
				{"        "}5. Limitation of Liability{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of quoridynasty.com.{"\n    "}
			</Text>
			{"    "}
			<Text font="--headline4">
				{"        "}6. Changes to Terms{"\n    "}
			</Text>
			{"    "}
			<Text>
				{"        "}We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on our website. Your continued use of the game following such changes constitutes your acceptance of the new Terms.{"\n    "}
			</Text>
			{"            "}
		</Section>
		<Components.Footer>
			<Override slot="text3">
				unit 19A/87 Catalano Cct,{" "}
				<br />
				Canning Vale WA 6155, Australia
			</Override>
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n  scroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});